//
// footer.scss
//
.footer {
    position: relative;

    .footer-head {
        font-size: 16px;
        letter-spacing: 1px;
        font-weight: 500;
    }

    .text-foot {
        color: $gray-300;
        font-size: 16px;
    }

    .footer-list {
        margin-bottom: 0;

        li {
            margin-bottom: 10px;

            a {
                &:hover {
                    color: $custom;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.contact-foot {
            i {
                font-size: 16px;
            }
        }
    }

    .footer-recent {
        .footer-recent-thumb {
            width: 25%;
        }

        .footer-recent-content {
            width: 75%;
            padding-left: 10px;

            a {
                color: $gray-500;
                transition: all 0.5s ease;

                &:hover {
                    color: $custom;
                }
            }
        }
    }
}

.footer-bar {
    border-top: 1px solid $gray-700;
}