//
// Bootstrap-custom.scss
//
html {
    scroll-padding-top: 59px;
    /* height of sticky header */
}

.sigCanvas {
    border: 1px solid #000;
    margin-top: 10px;
}

.sticky-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 1000;
    padding: 15px 30px;
    /* Default padding for larger screens */
    font-size: 16px;
    /* Default font size for larger screens */
}

/* For screens smaller than 768px (tablet size and smaller) */
@media (max-width: 768px) {
    .sticky-button {
        padding: 10px 20px;
        /* Reduce padding for smaller screens */
        font-size: 14px;
        /* Reduce font size for smaller screens */
        bottom: 60px;
        /* Adjust position from the bottom */
        right: 15px;
        /* Adjust position from the right */
    }
}

/* For screens smaller than 480px (mobile size) */
@media (max-width: 480px) {
    .sticky-button {
        padding: 6px 14px;
        /* Further reduce padding for mobile screens */
        font-size: 10px;
        /* Further reduce font size for mobile screens */
        bottom: 50px;
        /* Adjust position for mobile screens */
        right: 10px;
        /* Adjust position for mobile screens */
    }
}


.shake-button {
    // background-color: #ff6f61;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.shake-button:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}


.custom-link {
    background-color: #ff4081;
    /* Background color */
    color: white;
    /* Text color */
    padding: 10px 20px;
    /* Padding around the text */
    text-decoration: none;
    /* Remove the default underline */
    border-radius: 5px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    display: inline-block;
    /* Make it behave like a button */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: background-color 0.3s ease;
    /* Smooth transition on hover */
}

.custom-link:hover {
    background-color: #e91e63;
    /* Darker shade when hovered */
}

.custom-margin-top {
    margin-top: "-20%";
    /* or any value you prefer */
}

.container {
    @media (min-width: 1400px) {
        max-width: 1140px !important;
    }
}

.kd-pg {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    /* Default for desktop */

    @media (max-width: 768px) {
        margin-top: 15%;
        /* For mobile screens */
    }
}

.row>* {
    position: relative;
}

@each $heading, $size in $font-sizes {
    #{$heading} {
        font-size: $size;
    }
}

@each $name, $value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }

    .bg-soft-#{$name} {
        background-color: rgba($value, 0.1) !important;
    }

    .text-#{$name} {
        color: #{$value} !important;
    }

    a {
        &.text-#{$name} {
            &:hover {
                color: darken($value, 5%) !important;
            }
        }
    }
}

//li (list inline item)
.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0px;
}

//Shadow
.shadow {
    box-shadow: $shadow !important;
}

// Rounded
.rounded {
    border-radius: 5px !important;
}

.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}

.rounded-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rounded-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rounded-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

// Border
.border {
    border: 1px solid $gray-200 !important;
}

.border-top {
    border-top: 1px solid $gray-200 !important;
}

.border-bottom {
    border-bottom: 1px solid $gray-200 !important;
}

.border-left {
    border-left: 1px solid $gray-200 !important;
}

.border-right {
    border-right: 1px solid $gray-200 !important;
}


//HOURS & PRICING
.hours-pricing-section {
    // background-color: #f0f4f7;
    position: relative;
    padding-bottom: 40px;
    padding: 60px 0;
    border-color: #7B3B84;
    border-width: 2;
    border-style: dotted;

    .section-title {

        /* Keeping the original style for title and subtitle */
        .title {
            font-size: 32px;
            color: #ff6f61;
            letter-spacing: 0.5px;
        }

        .text-muted {
            font-size: 16px;
            color: #6c757d;
        }
    }

    .pricing-category {
        margin-top: 40px;

        .category-title {
            font-size: 28px;
            color: #ff6f61;
            margin-bottom: 20px;
            text-align: center;
        }


        // .pricing-row {
        //     display: flex;
        //     justify-content: space-around;
        //     flex-wrap: wrap;
        //     // justify-content: center;
        //     text-align: center;
        // }
        .pricing-row {
            display: flex;
            flex-wrap: wrap;
            /* Ensure cards stack on smaller screens */
            justify-content: center;
            /* Horizontally center the cards */
            align-items: center;
            /* Vertically align the cards if necessary */
            margin-bottom: 20px;
        }


    }

    .pricing-card {
        perspective: 1000px;
        width: 250px;
        height: 200px;
        position: relative;
        margin-bottom: 20px;

        @media (max-width: 576px) {
            width: 80%;
            /* Set the card width to 90% on small screens */
            margin-left: auto;
            /* Center horizontally */
            margin-right: auto;
            /* Center horizontally */
            // height: auto * 2;
            /* Allow the height to adjust based on content */
        }

        cursor: pointer;

        &:hover .front {
            transform: rotateY(180deg);
        }

        &:hover .back {
            transform: rotateY(0deg);
        }

        .front,
        .back {
            width: 100%;
            height: 100%;
            position: absolute;
            backface-visibility: hidden;
            border-radius: 15px;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
            transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        }

        .front {
            background: linear-gradient(135deg, #ff6f61, #ffbb33);
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 24px;
            padding: 20px;
            border-radius: 15px;
        }

        .back {
            background: #fff;
            transform: rotateY(180deg);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 18px;
            color: #555;
            padding: 20px;
            border-radius: 15px;
        }
    }

    // .hours-title {
    //     font-size: 28px;
    //     color: #ff6f61;
    // }

    // .hours-detail {
    //     font-size: 18px;
    //     color: #333;
    //     margin-top: 15px;
    // }

}

.hours-section {
    background-color: #f9f9f9;
    padding: 40px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin: 30px 0;

    .hours-header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .hours-title {
        font-size: 28px;
        color: #ff6f61;
        text-align: center;
        margin-top: 10px;
    }

    .hours-details {
        margin-top: 20px;

        .hours-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 15px 0;
            border-bottom: 1px solid #ececec;

            &:last-child {
                border-bottom: none;
            }

            p {
                font-size: 18px;
                color: #333;
                margin: 0;

                strong {
                    color: #ff6f61;
                }
            }
        }

        .special-hours {
            background-color: #fff1e0;
            padding: 20px;
            border-radius: 10px;

            p {
                font-weight: bold;
            }
        }
    }
}


.funlandia-service {
    // background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }

    .content {
        margin-top: 20px;

        h4 {
            font-size: 22px;
            // color: #ff6f61;
        }

        p {
            font-size: 16px;
            color: #6c757d;
        }
    }
}

@keyframes customFadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.custom-animation {
    animation: customFadeIn 1s ease forwards;
}

.aos-section {
    padding: 50px;
    margin: 20px 0;
    background-color: #f0f4f7;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.asterisk-text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.asterisk-text {
    position: relative;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #ff6f61;
    font-style: italic;
}

.wave-container {
    position: absolute;
    top: -50px;
    /* Adjust this value as needed */
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 1;
}

.wave-image {
    width: 100%;
    height: auto;
}

@font-face {
    font-family: 'Cathalia';
    src: url('../fonts/Cathalia.ttf') format('truetype');
}

.handwriting-container {
    margin-top: 50px;
    /* Add more margin at the top */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Handwriting Text */
.handwriting-text {
    font-family: 'Cathalia', cursive;
    font-size: 50px;
    fill: none;
    stroke: #EB4573;
    stroke-width: 1px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    /* animation: handwriting 6s ease-in-out forwards; */
    animation: handwriting 6s ease-in-out forwards, fillText 1s 2s forwards;
    /* Two animations */


}

/* Animation Keyframes */
@keyframes handwriting {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fillText {
    to {
        fill: #EB4573;
        /* The same color as stroke */
        stroke: none;
        /* Remove stroke once the fill appears */
    }
}

@media (max-width: 767px) {
    .thumb {
        float: none !important;
        text-align: center;
    }
}

.asterisk-container {
    display: flex;
    justify-content: flex-start;
    /* Aligns the content to the left */
    padding-left: 10px;
    /* Optional: Adjusts the indentation */
}

/* styles.css */
.package-details {
    text-align: left;
}

.package-details p {
    margin-bottom: 0.5rem;
}

.package-details ul {
    padding-left: 1.2rem;
}

.package-details li {
    margin-bottom: 0.3rem;
}

.package-details strong {
    color: #000;
}

/* styles.css or your corresponding CSS file */

.service-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.service-container .content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.service-container .package-details {
    margin-top: auto;
    /* Pushes package-details to the bottom */
}

.service-container .title {
    min-height: 3rem;
    /* Adjust based on expected title size */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limits titles to 2 lines */
    -webkit-box-orient: vertical;
}

.btn-remove {
    background-color: #dc3545;
    /* Bootstrap's danger red */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.btn-remove:hover {
    opacity: 0.8;
    background-color: #dc3545;
    /* Bootstrap's danger red */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

/* App.css or Waiver.css */

/* Customize invalid-feedback for input fields */
.invalid-feedback {
    display: block;
    color: #ff6b6b;
    /* Soft red color */
    font-size: 0.9rem;
    font-weight: 500;
    position: relative;
    padding-left: 25px;
}

.invalid-feedback::before {
    content: "❌ ";
    /* Red cross icon */
    position: absolute;
    left: 0;
    top: 0;
}

/* Customize alert for signature and agreement errors */
.alert-danger {
    background-color: #ffe6e6;
    color: #ff4d4d;
    border: 1px solid #ffcccc;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alert-danger::before {
    content: "⚠️ ";
    /* Warning icon */
    margin-right: 10px;
}

/* Customize the Remove Child Button */
.btn-remove {
    background-color: #ff4d4d;
    /* Bright red */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-remove:hover {
    background-color: #e60000;
    /* Darker red on hover */
}

/* Optional: Add smooth transitions to error messages */
.invalid-feedback,
.alert-danger {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


/* Container for the custom checkbox */
.custom-checkbox-container-w {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;
    /* Adjust spacing as needed */
}

/* Hide the default checkbox */
.custom-checkbox-w {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Label pseudo-elements for custom checkbox */
.form-check-label-w::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 3px solid red;
    /* Red border when unchecked */
    border-radius: 4px;
    background-color: #fff;
    /* White background when unchecked */
    transition: background-color 0.3s, border-color 0.3s;
}

/* Checkmark styling */
.form-check-label-w::after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    /* Adjusted for better horizontal centering */
    top: 2px;
    /* Adjusted for better vertical centering */
    width: 6px;
    /* Increased width for better visibility */
    height: 12px;
    /* Increased height for better visibility */
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Show checkmark and change styles when checked */
.custom-checkbox-w:checked+.form-check-label-w::before {
    background-color: red;
    /* Red background when checked */
    border-color: red;
    /* Red border when checked */
}

.custom-checkbox-w:checked+.form-check-label-w::after {
    display: block;
    /* Show the checkmark */
}

/* Focus state for accessibility */
.custom-checkbox-w:focus+.form-check-label-w::before {
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
    /* Red focus ring */
}

/* Optional: Hover effect */
.custom-checkbox-container-w:hover .form-check-label-w::before {
    border-color: #cc0000;
    /* Darker red on hover */
}

/* Error state styling */
.is-invalid+.invalid-feedback {
    display: block;
    color: red;
    margin-top: 0.25rem;
}



///////////////////////////

// /* Gallery Section */
// .gallery-section {
//     padding: 10% 20px;
//     position: relative;
//     overflow: hidden;
//     background-color: #f9f9f9;
//     transition: padding 0.3s ease;
// }

// .gallery-section.mobile {
//     padding: 20% 10px;
// }

// .gallery-section.desktop {
//     padding: 10% 20px;
// }

// /* Gallery Container */
// .gallery-container {
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;
//     background: #ffffff;
//     border-radius: 15px;
//     box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
//     overflow-y: auto;
//     /* Enable vertical scrolling */
//     height: 80vh;
//     /* Set a fixed height; adjust as needed */
//     transition: height 0.3s ease;
// }

// .gallery-section.mobile .gallery-container {
//     height: 70vh;
// }

// .gallery-section.desktop .gallery-container {
//     height: 80vh;
// }

// /* Scrollbar Styling (Optional) */
// .gallery-container::-webkit-scrollbar {
//     width: 8px;
// }

// .gallery-container::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     border-radius: 10px;
// }

// .gallery-container::-webkit-scrollbar-thumb {
//     background: #c1c1c1;
//     border-radius: 10px;
// }

// .gallery-container::-webkit-scrollbar-thumb:hover {
//     background: #a8a8a8;
// }

// /* Gallery Grid */
// .gallery-grid {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
// }

// /* Gallery Row */
// .gallery-row {
//     display: flex;
//     gap: 20px;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     /* Align items to the start */
//     align-items: flex-start;
//     /* Align items based on their top edges */
// }

// /* Picture Item */
// .picture-item {
//     flex: 1;
//     min-width: 250px;
//     max-width: 100%;
//     position: relative;
//     overflow: hidden;
//     border-radius: 10px;
//     display: flex;
//     align-items: flex-start;
//     /* Align items based on their top edges */
// }

// /* Portfolio Box */
// .portfolio-box {
//     position: relative;
//     overflow: hidden;
//     border-radius: 10px;
//     width: 100%;
//     height: auto;
//     /* Allow height to adjust based on image */
//     transition: transform 0.3s ease;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .portfolio-box:hover {
//     transform: scale(1.02);
// }

// /* Gallery Image */
// .gallery-image {
//     width: 100%;
//     height: auto;
//     /* Allow height to adjust based on image */
//     object-fit: cover;
//     /* Ensures the image covers the container without distortion */
//     display: block;
//     border-radius: 10px;
//     transition: transform 0.3s ease;
// }

// /* Overlay Work */
// .overlay-work {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(30, 30, 30, 0.6);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     opacity: 0;
//     transition: opacity 0.3s ease, transform 0.3s ease;
//     border-radius: 10px;
// }

// .portfolio-box:hover .overlay-work {
//     opacity: 1;
// }

// /* Overlay Title */
// .overlay-title {
//     color: #ffffff;
//     font-size: 1.2rem;
//     text-align: center;
//     padding: 0 10px;
// }

// /* Responsive Adjustments */
// @media (max-width: 768px) {
//     .gallery-container {
//         height: 60vh;
//     }

//     .gallery-row {
//         flex-direction: column;
//         gap: 15px;
//         align-items: center;
//         /* Center items vertically on mobile */
//     }

//     .picture-item {
//         min-width: 100%;
//     }

//     .gallery-section.mobile .gallery-container {
//         height: 70vh;
//     }
// }


/* Gallery Section */
.gallery-section {
    padding: 10% 20px;
    position: relative;
    overflow: hidden;
    background-color: #f9f9f9;
    transition: padding 0.3s ease;
}

.gallery-section.mobile {
    padding: 20% 10px;
}

.gallery-section.desktop {
    padding: 8% 20px;
}

/* Gallery Container */
.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* Enable vertical scrolling */
    height: 80vh;
    /* Set a fixed height; adjust as needed */
    transition: height 0.3s ease;
}

.gallery-section.mobile .gallery-container {
    height: 70vh;
}

.gallery-section.desktop .gallery-container {
    height: 80vh;
}

/* Scrollbar Styling (Optional) */
.gallery-container::-webkit-scrollbar {
    width: 8px;
}

.gallery-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.gallery-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
}

.gallery-container::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

/* react-masonry-css default styles */
.my-masonry-grid {
    display: flex;
    margin-left: -20px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 20px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style individual items */
.picture-item {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.picture-item:hover {
    transform: scale(1.02);
}

.portfolio-box {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.overlay-work {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 30, 30, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    border-radius: 10px;
}

.picture-item:hover .overlay-work {
    opacity: 1;
}

.overlay-title {
    color: #ffffff;
    font-size: 1.2rem;
    text-align: center;
    padding: 0 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .gallery-container {
        height: 60vh;
    }

    .gallery-section.mobile .gallery-container {
        height: 70vh;
    }
}