//
// team.scss
//
.team-concept {
    .team-image {
        .team-overlay {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            transition: all 0.5s ease;
            padding: 8px 0 4px;
        }
    }
    .content {
        .name {
            font-size: 17px;
            font-weight: 500;
            transition: all 0.5s ease;
        }
    }
    &:hover {
        .team-image {
            .team-overlay {
                opacity: 0.9;
            }
        }
    }
}