//
// general.scss
//
body {
    font-family: $font-family-secondary;
    overflow-x: hidden !important;
    font-size: $font-size-base;
    color: $dark;
    // padding-top: 85px;
    background-color: #FAFAFB;
    min-height: 100vh;
    max-width: 100%;
    // box-sizing: border-box;
    // overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 600;
}

::selection {
    background: $gray-600;
    color: $white;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

p {
    letter-spacing: 0.3px;
    line-height: 1.8;
}

.m-x-2 {
    margin: 0 2px;
}