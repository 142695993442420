//
// home.scss
//
@mixin home-common {
    background-size: cover;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-home {
    height: 100vh;
    @include home-common();

    .home-dashboard {
        img {
            position: relative;
            top: 80px;
            z-index: 1;
        }
    }
}

// flex slider (Restaurent home)
.main-slider {
    .slides {
        .bg-slider {
            background-size: cover !important;
            height: 100vh;
            position: relative;
            @include home-common();
        }
    }
}

.flex-control-nav {
    display: none;
}

.bg-half-170 {
    padding: 170px 0;
    @include home-common();
}

.bg-half-260 {
    padding: 260px 0;
    @include home-common();
}

// Portfolio HOME
#sThemes-layout {
    .sThemesbox {
        background: $white;
        box-shadow: $shadow;
        max-width: 1170px;
        margin: 0 auto;
    }
}

// Swiper Slider (Charity Home)
.swiper-slider-interior {
    height: 100vh;

    .swiper-container {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .swiper-slide {
            .slide-inner {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background-size: cover;
                background-position: center;
                display: flex;
                align-items: center;
                text-align: left;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            background: transparent;
            width: 35px;
            height: 35px;
            line-height: 35px;
            margin-top: -30px;
            text-align: center;
            transition: all 0.3s;

            &:before {
                font-family: "Material Design Icons";
                font-weight: 900;
                color: $white;
            }

            &:hover {
                background: $custom;
            }
        }

        .swiper-button-prev {
            left: 25px;

            &:before {
                content: "\f0141";
            }
        }

        .swiper-button-next {
            right: 25px;

            &:before {
                content: "\f0142";
            }
        }
    }

    .swiper-pagination-bullet {
        font-size: 15px;
        color: $gray-300;
        margin: 0 5px;
        opacity: 1;
        background: transparent;
        position: relative;

        &:before {
            content: "";
            background: $white;
            width: 1px;
            height: 15px;
            position: absolute;
            left: 3px;
            top: 0;
            opacity: 0;
            transition: all 0.3s;
        }
    }

    .swiper-pagination-bullet-active {
        color: $white;

        &:before {
            opacity: 1;
            top: -20px;
        }
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 45px;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 13px;
    }
}

// Business
.home-slider {

    .carousel-control-next,
    .carousel-control-prev {
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 30px;
        border: 1px solid $gray-400;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        width: 30%;
    }
}

@media (max-width: 1200px) {
    .sThemesbox {
        width: 100%;
        overflow: hidden;
    }
}

@media (max-width: 991px) {
    .swiper-slider-interior {
        height: 600px;

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }
}

@media (min-width: 200px) and (max-width: 767px) {

    .bg-home,
    .main-slider .slides .bg-slider,
    .swiper-slider-interior .swiper-container .swiper-slide,
    .jarallax,
    .bg-half-170,
    .bg-half-260 {
        padding: 150px 0;
        height: auto;
    }

    .home-img {
        text-align: center;
    }
}