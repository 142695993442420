//
// menu.scss
//
.navbar-custom {
    background-color: transparent;
    padding: 20px 0;
    width: 100%;
    border-radius: 0px;
    z-index: 999;
    margin-bottom: 0px;
    transition: all 0.5s ease-in-out;
    overflow-x: hidden;
    /* Prevent overflow */


    .navbar-toggler {
        color: $dark;
        font-size: 30px;
        padding: 0px;
        border: 0;

        &:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }

        &.toggler-dark {
            color: $dark !important;
        }
    }

    .navbar-brand {
        color: $white !important;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-family: $font-family-base;

        .l-dark,
        .logo-dark-mode {
            display: none;
        }

        .l-light,
        .logo-light-mode {
            display: inline-block;
        }

        @media (max-width: 991.98px) {
            .l-dark {
                display: inline-block !important;
            }

            .l-light {
                display: none !important;
            }
        }
    }

    .navbar-nav {
        li {
            a {
                color: $black !important;
                font-size: 14px;
                background-color: transparent !important;
                margin: 0 6px;
                letter-spacing: 0.8px;
                line-height: 24px;
                font-weight: 600;
                transition: all 0.5s;
                font-family: $font-family-base;

                &:hover,
                &:active,
                &.active {
                    color: $custom !important;
                }
            }
        }

        .nav-item {

            &:last-child {
                .nav-link {
                    margin-right: 0;
                    padding-right: 0;

                }
            }
        }

        &.menu-dark {
            li {
                a {
                    color: $dark !important;

                    &:hover,
                    &:active,
                    &.active {
                        color: $custom !important;
                    }
                }

                &.active a {
                    color: $custom !important;
                }
            }
        }
    }

    //login button
    .login-button {
        color: $white;
        background-color: $custom;
        text-transform: uppercase;
        font-size: 13px;
        transition: all 0.5s;
        border-radius: 0px;
        padding: 10px 30px;
        line-height: 60px;
        border-radius: 3px;
        letter-spacing: 0.8px;

        &:hover {
            color: $white;
        }
    }

    &.boxed-home {
        max-width: 1170px;
        margin: 0 auto;
    }
}

// Nav Sticky
.nav-sticky {
    &.navbar-custom {
        margin-top: 0px;
        padding: 12px 0px;
        background-color: $white;
        box-shadow: 0 10px 33px rgba($black, .1);
        color: $dark !important;

        .navbar-toggler {
            color: $dark;
        }

        .navbar-nav {
            margin-top: 0px;

            li {
                a {
                    color: $dark !important;

                    &:hover,
                    &:active,
                    &.active {
                        color: $custom !important;
                    }
                }
            }
        }

        &.sticky-light {
            background-color: $white;
        }
    }

    .navbar-brand {
        color: $dark !important;

        .l-dark {
            display: inline-block;
        }

        .l-light {
            display: none;
        }
    }
}

@media (max-width: 1023px) {
    .navbar-custom .nav-social {
        display: none;
    }
}

@media (max-height: 480px) {
    .navbar-nav {
        max-height: 200px;
        overflow-y: auto;
    }
}

@media (min-width: 200px) and (max-width: 768px) {
    .navbar-custom {
        background-color: $white;
        padding: 10px 0px !important;

        .login-button {
            margin-left: 5px !important;
        }

        .navbar-nav {
            li {
                a {
                    color: $dark !important;
                }
            }
        }
    }

    .navbar-brand {
        .l-dark {
            display: inline-block !important;
        }

        .l-light {
            display: none !important;
        }
    }
}