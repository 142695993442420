


.service-container {
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #f9f9f9; 
    border: 1px solid #e0e0e0; 
  }
  
  .service-container:hover {
    transform: translateY(-5px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  
  /* .number {
    display: inline-block;
    background-color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 1.5rem;
    color: #41c1cc;
    margin-bottom: 15px;
  } */
  
  
  .pricing-tier {
    font-size: 1.1rem;
    margin-left: 1rem; 
  }
  
  
  .additional-child p {
    color: #333; 
  }
  
  .additional-price {
    margin-left: 1rem; 
  }
  
  .additional-price-tier {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  
  .btn-primary {
    background-color: #41c1cc;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #339bb3;
  }
  
  
  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  
  .serv-pera {
    font-size: 1rem;
    color: #6c757d;
  }
  
  
  .form-check-label {
    margin-left: 0.5rem;
    font-weight: 500;
  }
  
  
  @media (max-width: 767.98px) {
    .pricing-options {
      flex-direction: column;
    }
  
    .additional-price {
      margin-left: 0; 
    }
  }
  

  /* src/assets/css/Reservation.css */

/* Custom Tabs Styling */
.custom-tabs .nav-tabs .nav-link {
    color: #41c1cc; /* Inactive tab text color */
    font-weight: 500;
    transition: color 0.3s, background-color 0.3s;
  }
  
  .custom-tabs .nav-tabs .nav-link:hover {
    color: #339bb3; /* Inactive tab hover color */
  }
  
  .custom-tabs .nav-tabs .nav-link.active {
    color: #fff; /* Active tab text color */
    background-color: #41c1cc; /* Active tab background color */
    border-color: #41c1cc #41c1cc #fff; /* Adjust border colors to match */
  }
  
  .custom-tabs .nav-tabs .nav-link.active:hover {
    color: #fff;
    background-color: #339bb3; /* Darker shade on hover */
    border-color: #339bb3 #339bb3 #fff;
  }
  
  /* Optional: Border and Indicator Customization */
  .custom-tabs .nav-tabs {
    border-bottom: 2px solid #41c1cc; /* Bottom border for tabs */
  }
  
  .custom-tabs .nav-tabs .nav-link.active::after {
    background-color: #fff; /* Indicator color */
  }
  


  /* src/assets/css/Reservation.css */

/* Custom Toggle Switch Styling */
.custom-toggle 
/* .form-check-input {
    width: 5em;
    height: 1.5em;
    background-color: #ccc; 
    border-radius: 1.5em;
    position: relative;
    transition: background-color 0.3s;
  } */
  
  .custom-toggle .form-check-input::before {
    content: '';
    position: absolute;
    top: 0.125em;
    left: 0.125em;
    width: 1.25em;
    height: 1.25em;
    background-color: #fff; /* Knob color */
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  .custom-toggle .form-check-input:checked {
    background-color: #41c1cc; /* Active background color */
  }
  
  .custom-toggle .form-check-input:checked::before {
    transform: translateX(1.5em); /* Move the knob to the right */
  }
  
  /* Optional: Focus State Styling */
  .custom-toggle .form-check-input:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 193, 204, 0.20); /* Match active color */
  }
  