//
// cta.scss
//
.cta-full {
    .container-fluid {
        max-width: 100%;

        .padding-less.img {
            background: scroll center no-repeat;
            background-size: cover;
            position: absolute;
            height: 100%;
        }
    }

    .cta-full-img-box {
        padding: 100px 10%;

    }

    .cta-full-head {
        font-size: 22px;
        letter-spacing: 0.8px;

    }
}


//home button
.watch-video a {
    font-size: 14px;
    letter-spacing: 1px;

    .play-icon-circle {
        height: 50px;
        width: 50px;
        background-color: $custom;
        line-height: 50px;

        &.play-iconbar {
            font-size: 17px;
        }

        &.play {
            top: 77%;
        }
    }
}



.play-icon {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: 0;
    left: 0;
    text-align: center;


    i {
        height: 85px;
        width: 85px;
        line-height: 85px;
        -webkit-text-stroke: 2px $custom;
        -webkit-text-fill-color: transparent;
        display: inline-block;

        &:hover {
            -webkit-text-stroke: 2px $custom;
            -webkit-text-fill-color: $custom;
        }
    }
}

//cta
// .play-icon {
//     position: absolute;
//     bottom: 50%;
//     transform: translateY(50%);
//     right: 0;
//     left: 0;
//     z-index: 1;
//     i {
//         height: 85px;
//         width: 85px;
//         font-size: 30px;
//         border: 5px solid $gray-200;
//         line-height: 75px;
//         display: inline-block; 
//         background: $custom;
//     }
// }

@media (max-width: 1024px) {
    .cta-full {
        .cta-full-img-box {
            padding: 100px 10px !important;
        }
    }
}

@media (max-width: 991px) {
    .cta-full {
        .padding-less {
            position: relative !important;
            height: 300px !important;
        }

        .cta-full-content {
            text-align: left !important;
        }
    }
}

@media (max-width: 768px) {
    .cta-full {
        .cta-full-img-box {
            padding: 60px 0%;

        }
    }
}

@media (max-width: 767px) {

    .contact-cta,
    .contact-cta-button {
        text-align: center !important;
    }
}