//
// blog.scss
//
.blog-post {
    .content {
        .title {
            transition: all 0.5s ease;
            &:hover {
                color: $custom !important;
            }
        }
        a {
            transition: all 0.5s ease;
            &:hover {
                color: $custom !important;
            }
        }
    }
}