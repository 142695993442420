//
// helper.scss
//

.section {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}

.section-two {
    padding: 60px 0;
    position: relative;
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-white {
    background-color: $bg-overlay-white;
}

.bg-over-image {
    background-color: rgba($dark, 0.5) !important;
    background-image: url('../images/overlay.png');
    background-repeat: repeat;
}

.bg-gradient-overlay-2 {
    background: linear-gradient(135deg, darken($primary, 10%), darken($success, 10%));
}

.bg-gradient-overlay {
    background: linear-gradient(135deg, $primary, $custom);
    opacity: 0.95;
}

.title-heading {
    line-height: 26px;

    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
    }

    .para-desc {
        font-size: 18px;
    }
}

.mt-100 {
    margin-top: 100px;
}

.section-title {
    position: relative;

    .title {
        letter-spacing: 0.5px;
        font-size: 26px !important;
    }
}

.para-desc {
    max-width: 750px;
}

@media (max-width: 768px) {
    .mt-60 {
        margin-top: 60px;
    }
}

@media (min-width: 768px) {
    .section-two {
        &.section-spacing {
            padding-bottom: 120px;
        }
    }

    .margin-top-150 {
        margin-top: 150px;

        .heading {
            font-size: 40px;
        }
    }
}

@media (max-width: 767px) {
    .section {
        padding: 60px 0;
    }

    .title-heading {
        .heading {
            font-size: 30px !important;
        }
    }
}

@media (max-width: 375px) {
    .section-title {
        .title {
            &:after {
                bottom: -30%;
            }
        }
    }
}

.carousel .thumbs-wrapper {
    // margin: -15px !important;
}