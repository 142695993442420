//
// portfolio.scss
//
.filter-box {
    display: none;

    &.show {
        display: block;
    }
}

.container-filter {
    li {
        font-size: 14px;
        letter-spacing: 1px;
        cursor: pointer;
        line-height: 30px;
        transition: all 0.5s ease;
        font-weight: bold;

        &:hover,
        &.active {
            color: $white !important;
            background-color: $custom;
            border-color: $custom !important;
        }
    }
}

.portfolio-box {
    .work-img {
        .overlay-work {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: all 0.9s ease;
            transform: scale(1);
            background-color: rgba($black, .50);
            z-index: 1;
            border: 20px solid $gray-400;

            .icon {
                position: absolute;
                bottom: 50%;
                transform: translateY(0%);
                transition: all 0.5s ease;
                right: 0;
                left: 0;

                i {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 20px;
                    margin: 0 auto;
                    display: inline-block;

                    &:hover {
                        color: $custom !important;
                        background: $white;
                    }
                }
            }

            .work-content {
                position: absolute;
                bottom: 5%;
                left: 5%;
                transform: translateY(100px);
                transition: all 0.5s ease;

                .title {
                    a {
                        font-size: 18px;
                    }

                    &:hover a {
                        color: $custom !important;
                    }
                }
            }
        }
    }

    &:hover {
        .work-img {
            &.main-box {
                transform: translateY(-105px);
            }
        }

        .overlay-work {
            opacity: 1;
            transform: translateY(0px);

            .icon {
                transform: translateY(50%);
            }

            .work-content {
                transform: translateY(0px);
            }
        }
    }
}

// Charity
.causes-container {
    .content {
        a {
            transition: all 0.5s ease;

            &:hover {
                color: $custom !important;
            }
        }
    }
}

.spacing {
    padding-left: 5px !important;
    padding-right: 5px !important;
}



/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
//Tobii Lighbox
//Tobii Lighbox
.tobii__btn svg {
    height: 20px;
    width: auto;
}

.tobii__counter {
    font-size: 16px;
}

.tobii-zoom {
    display: block !important;
}

.tobii-zoom__icon {
    display: none;
}

#grid {
    padding: 0 !important;
}