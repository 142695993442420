//
// contact.scss
//
.form-control {
    box-shadow: none;
    border: 1px solid $gray-200;
    height: 40px;
    font-size: 13px;
    border-radius: 3px;
    transition: all 0.5s ease;
    padding: 6px 12px !important;
    &:focus, &.active {
        border-color: $custom;
    }
}
textarea {
    height: 120px !important;
    &.form-control {
        line-height: 25px;
    }
}
.map {
    line-height: 0;
    iframe {
        width: 100%;
        height: 450px;
    }
}

.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message,
#success_page {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 5px;
}

.error_message {
    background-color: rgba($danger, 0.1) !important;
    border: 2px solid rgba($danger, 0.1) !important;
    color: $danger !important;
    font-size: 14px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    background-color: rgba($success, 0.1) !important;
    border: 2px solid rgba($success, 0.1) !important;
    color: $success !important;
    p {
        margin-bottom: 0 !important;
    }
}

//Subscribe 
.subscribe {
    .form-control {
        background-color: transparent;
        height: 40px;
        color: $light;
        font-size: 14px;
        &::placeholder{
            color: $light;
        }
        &:focus {
            box-shadow: none;
            border-color: $custom;
        }
    }
}