/* CarouselComponent.css */

/* Allow vertical scrolling and prevent carousel from intercepting vertical swipes */
.custom-carousel {
    touch-action: pan-y;
  }
  
  /* Ensure the carousel slides allow vertical touch actions */
  .custom-carousel .carousel .slide {
    touch-action: pan-y;
  }
  
  /* Optional: Adjust the z-index if necessary */
  .home-slider {
    z-index: 1; /* Adjust as needed */
  }
  
  /* Ensure the carousel doesn't block other elements */
  .carousel-item {
    position: relative;
    z-index: 1; /* Ensure it's below other content if needed */
  }
  
  .home-slider {
    z-index: 1; /* Ensure it's below other interactive elements if necessary */
  }