//
// .scss
//
.features {
    position: relative;
    z-index: 2;
    margin: -160px 0 0;

    &.features-two {
        margin: -220px 0 0;
    }

    .title {
        letter-spacing: 1px;
    }

    a {
        &:hover {
            color: $custom !important;
        }
    }
}

.feature-margin {
    margin-top: 100px;
}

.features-box {
    .title-icon {
        font-size: 25px;
        height: 60px;
        width: 60px;
        line-height: 60px;
        background: $gray-100;
    }

    .title {
        font-size: 18px;
    }

    ul {
        li {
            position: relative;
            padding: 10px 0;
        }
    }
}

//Services
.service-container {
    transition: all 0.5s ease;

    .icon {
        margin-top: 5%;
        font-size: 30px;
        transition: all 0.5s ease;
    }

    .number-icon {
        .icon-2 {
            height: 70px;
            width: 70px;
            line-height: 70px;
            border-radius: 64% 36% 55% 45% / 76% 72% 28% 24% !important;
            border-color: $custom !important;
            transition: all 0.5s ease;

            i {
                font-size: 30px;
            }
        }

        .icon-kd {
            height: 500px;
            width: 500px;
            line-height: 70px;
            border-radius: 64% 36% 55% 45% / 76% 72% 28% 24% !important;
            border-color: $custom !important;
            transition: all 0.5s ease;

            i {
                font-size: 30px;
            }
        }

        .number {
            position: absolute;
            top: 0;
            right: 70px;
            left: 0;
            height: 35px;
            width: 35px;
            margin: 0 auto;
            transition: all 0.5s ease;

            span {
                line-height: 30px;
            }
        }
    }

    .content {
        .number {
            font-size: 40px;
            color: $gray-300;
        }

        .title {
            transition: all 0.5s ease;
        }
    }

    &.hover-bg {
        transition: all 0.5s ease;

        .smooth-icon {
            position: absolute;
            bottom: -40px;
            right: -20px;
            font-size: 60px;
            color: $gray-100;
            transition: all 0.8s ease;
        }

        &:hover {
            background-color: $custom;

            .content {
                .title {
                    color: $white !important;
                }

                .serv-pera {
                    color: $light !important;
                }
            }

            .smooth-icon {
                font-size: 100px;
                opacity: 0.2;
                bottom: -20px;
                right: 10px;
            }
        }
    }

    &:hover {
        background-color: $white;
        box-shadow: $shadow-lg !important;

        .icon {
            color: $custom;
            animation: mover 1s infinite alternate;
        }

        .number-icon {
            .icon-2 {
                background-color: $custom;
                border-radius: 50% !important;

                i {
                    color: $white !important;
                }
            }

            // .icon-kd {
            //     background-color: $custom;
            //     border-radius: 50% !important;

            //     i {
            //         color: $white !important;
            //     }
            // }

            .number {
                color: $white;
                background: $custom !important;
                border-color: $white !important;
            }
        }

        .content {
            .title {
                color: $custom;
            }
        }
    }

    a {

        &:hover,
        .title:hover {
            color: $custom !important;
        }
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

//
// .scss
//
.service-wrapper {
    transition: all 0.5s ease;

    .icon {
        width: 60px;
        height: 60px;
        line-height: 45px;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &.icon-uil {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: inherit;
        }
    }

    .content {
        .title {
            transition: all 0.5s ease;
            font-weight: 500;
        }
    }

    .big-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0.05;
        transition: all 0.5s ease;
    }

    &:hover {
        box-shadow: $shadow-lg !important;
        background: $white !important;
        transform: translateY(-8px);
        border-color: transparent !important;

        .icon {
            background: rgba($custom, 1) !important;
            color: $white !important;
        }

        .big-icon {
            z-index: -1;
            opacity: 0.1;
            font-size: 160px;
        }

        &.features-port {
            transform: translateY(0);
            box-shadow: none !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .features {
        &.features-two {
            margin: 0 0 30px;
        }

        &.feature-charity {
            margin: 0 0 30px;
        }
    }

    .feature-margin {
        margin-top: 60px;
    }
}

.feature-app {
    .icon-box {
        height: 65px;
        width: 65px;
        background-color: $custom !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .service-icon {
            padding-top: 0px;
        }
    }

    .feature-text,
    .feature-icon {
        color: $custom !important;
    }

}

// .process-bar:after {
//     display: block;
//     width: 50%;
//     position: absolute;
//     content: '';
//     border-width: 0 0 1px 0;
//     border: 1px
//  #e9ecef dashed;
//     top: 15%;
//     left: 75%;
// }
.process {
    .icon {
        width: 60px;
        height: 60px;
        line-height: 45px;
        transition: all 0.5s ease;

        &.icon-uil {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: inherit;
        }
    }

    &.process-bar:after {
        display: block;
        width: 50%;
        position: absolute;
        content: ' ';
        border-width: 0 0 1px 0;
        border: 1px #e9ecef dashed;
        top: 20%;
        left: 75%;

        @media (max-width: 767px) {
            display: none;
        }
    }
}