//
// testi.scss
//
.client-review {
    .review-para {
        font-size: 16px;
    }
    .big-icon {
        position: absolute;
        font-size: 160px;
        bottom: 0;
        right: 0;
        opacity: 0.04;
        z-index: -1;
    }
}


.tns-nav {
    text-align: center;
    margin-top: 10px;
    button {
        padding: 4px;
        opacity: 1;
        margin: 3px;
        border-radius: 10px;
        background: transparent !important;
        transition: all 0.5s ease;
        border: 2px solid rgba($custom, 0.3);
        &.tns-nav-active {
            background: $custom !important;
            border-color: $custom;
        }
    }
}